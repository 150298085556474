<template>
  <div class="profile-security v-step-security-section">
    <div class="title">
      <h1>{{ $t('profile.security.title') }}</h1>
    </div>
    <div class="content">
      <div class="change-password subtitle">
        <h3>{{ $t('profile.security.change-password') }}</h3>
      </div>
      <div class="current-password">
        <label for="current-password">
          {{ $t('profile.security.current-password') }}
          <span class="orange">*</span>
        </label>
        <div class="custom-input primary">
          <CustomInput
            type="password"
            inputClasses="box col-all-12"
            autocomplete="current-password"
            name="current-password"
            :placeholder="$t('profile.security.current-password_placeholder')"
            v-model="$v.currentPassword.$model"
            :model="$v.currentPassword"
            :error="fieldsErrors.currentPassword"
          />
        </div>
      </div>
      <div class="new-password">
        <label for="new-password">
          {{ $t('profile.security.new-password') }}
          <span class="orange">*</span>
        </label>
        <div class="custom-input primary">
          <CustomInput
            type="password"
            inputClasses="box col-all-12"
            autocomplete="new-password"
            name="new-password"
            :placeholder="$t('profile.security.new-password_placeholder')"
            v-model="$v.password.$model"
            :model="$v.password"
            :error="fieldsErrors.password"
          />
        </div>
      </div>
      <div class="confirm-new-password">
        <label for="confirm-new-password">
          {{ $t('profile.security.confirm-new-password') }}
          <span class="orange">*</span>
        </label>
        <div class="custom-input primary">
          <CustomInput
            type="password"
            inputClasses="box col-all-12"
            autocomplete="new-password"
            name="retyped-password"
            :placeholder="$t('profile.security.confirm-new-password_placeholder')"
            v-model="$v.retypedPassword.$model"
            :model="$v.retypedPassword"
            :error="fieldsErrors.retypedPassword"
          />
        </div>
      </div>
      <div class="save-button">
        <button class="orange-full" :class="{ spinner: isPasswordLoading }" @click="savePassword">
          {{ $t('profile.security.save-changes') }}
        </button>
      </div>
      <div class="two-factor-authenfication">
        <div class="subtitle">
          <h3>{{ $t('profile.security.two-factor-authentification') }}</h3>
        </div>
        <div class="coming-soon">
          <h3>{{ $t('profile.security.coming-soon') }}</h3>
        </div>
      </div>
      <!-- <div class="save-button">
        <button class="orange-full" >{{ $t('profile.security.save-changes') }}</button>
      </div>-->
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/user-interfaces.less"></style>

<script>
import { required, sameAs } from 'vuelidate/lib/validators'
import validationMessage from '@/validationMapping'

export default {
  name: 'profile-security',
  data() {
    return {
      fieldsErrors: {},
      isPasswordLoading: false,
      currentPassword: '',
      password: '',
      retypedPassword: ''
    }
  },
  validations: {
    currentPassword: {
      required
    },
    password: {
      required
    },
    retypedPassword: {
      required,
      sameAs: sameAs('password')
    }
  },
  methods: {
    savePassword() {
      this.isPasswordLoading = true

      if (this.$v.$invalid) {
        this.$store.dispatch('errorManager/showError', { error: 'FIX_ERRORS' })
        this.fieldsErrors = {
          currentPassword: validationMessage(this.$v.currentPassword),
          password: validationMessage(this.$v.password),
          retypedPassword: validationMessage(this.$v.retypedPassword)
        }

        this.isPasswordLoading = false
        return
      }

      this.$store
        .dispatch('profile/updateUser', { currentPassword: this.currentPassword, newPassword: this.password })
        .then(() => {
          this.isPasswordLoading = false

          this.currentPassword = ''
          this.password = ''
          this.retypedPassword = ''

          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
        .catch(error => {
          this.isPasswordLoading = false
          console.error(error)

          error.graphQLErrors.map(({ extensions }) => {
            this.$store.dispatch('errorManager/showError', { error: Object.values(extensions)[0] })
          })
        })
    }
  }
}
</script>
